import * as toastr from 'toastr';
import React, { Component } from 'react';

import api from '../../utils/api';
import SimpleTab from '../SimpleTab/SimpleTab';
import { AppContext } from '../../contexts/AppContext';
import FoodDetail from './FoodDetail';
import * as moment from 'moment';


class FoodAdmin extends Component {

    static contextType = AppContext;

    constructor() {
        super();
        this.state = {
            foods: [],
            detail: null,
            exporting: false
        }

        this.onEdit = this.onEdit.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.export = this.export.bind(this);
    }

    componentDidMount() {
        this.loadfoods();
    }

    async loadfoods() {
        try {
            let response = await api.getAdminFoods();
            let foods = response.data || [];
            this.setState({ foods });
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    onEdit(item) {
        let detail = { ...item, ts: new Date().getTime() };
        this.setState({ detail });
    }

    onAdd() {
        let detail = { ts: new Date().getTime() };
        this.setState({ detail });
    }

    export() {
        let { translate } = this.context;

        this.setState({ exporting: true }, async () => {
            try {
                let response = await api.exportFood();
                console.log(response);
                var type = response.headers['content-type'] || 'application/json';
                var blob = new Blob([response.data], { type });

                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `food-items-export-${moment().format('YYYY-MM-DD-HH-mm-ss')}.json`;
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.log(error);
                toastr.error(translate('Err.System'));
            } finally {
                this.setState({ exporting: false });
            }
        });
    }

    async onCopy(item) {
        let { translate } = this.context;

        let detail = { ts: new Date().getTime() };
        if (item && item.id) {
            let response = await api.GetFood(item.id);
            let { translations, deleted, unit, healthyIndex, categories, params, features } = response.data || {};
            if (translations.length) {
                translations.forEach(o => {
                    if (o && o.name) {
                        o.name = o.name + translate('CopyPostfix', { lng: o.lang })
                    }
                });
            }
            detail = { ...detail, translations, deleted, unit, healthyIndex, categories, params, features };
        } else {
            console.log('No item id.');
        }
        this.setState({ detail });
    }

    canCopy(item) {
        return item && !item.isRecipe;
    }

    render() {
        let { translate } = this.context;
        let { foods, detail, exporting } = this.state;

        let showDetail = !!detail;

        return (
            <div className="food-admin">
                <div className="panel-header">{translate('FoodAdmin.Title')}</div>
                <SimpleTab
                    cols={[
                        { key: 'name', name: translate('FoodAdmin.Name'), style: { width: '40%' } },
                        { key: 'categories', name: translate('FoodAdmin.Category'), style: { width: '40%' }, getValue: (item => (item.categories || []).map(o => o.name).join('; ')) },
                        { key: 'energy', name: translate('FoodAdmin.Energy'), style: { width: '10%' }, valueType: 'number', round: true },
                        { key: 'healthyIndex', name: translate('FoodAdmin.Health'), style: { width: '10%' }, getValue: (item => translate(`HealthIndex.${item.healthyIndex}`)) },
                        { key: 'deleted', name: translate('ActivityAdmin.Deleted'), valueType: 'bool' }
                    ]}
                    data={foods}
                    onEdit={this.onEdit}
                    onAdd={this.onAdd}
                    onCopy={this.onCopy}
                    canCopy={this.canCopy}
                    onExport={this.export}
                    exporting={exporting}
                />

                {showDetail &&
                    <FoodDetail
                        key={detail.ts}
                        data={detail}
                        onClose={() => this.setState({ detail: null })}
                        onSubmit={() => {
                            this.loadfoods()
                            this.setState({ detail: null });
                        }}
                    />
                }
            </div>
        );
    }
}

export default FoodAdmin;